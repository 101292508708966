<template>
  <div>
    <navBar/>
    <Spinner/>
      <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div class="container-xl px-4">
                        <div class="row justify-content-center">
                            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div class="card my-5">
                                    <div class="card-body p-5 text-center">
                                        <div class="h3 fw-light mb-3 titulo-login">Iniciar Sesi&oacute;n</div>
                                    </div>
                                    <hr class="my-0" />
                                    <div class="card-body p-5">
                                        <form>
                                            <div class="mb-3 text-left">
                                                <label class="text-gray-600 small " for="emailExample">Email</label>
                                                <input class="form-control form-control-solid" v-on:keyup.enter="redirectHome" v-model="user" type="text" placeholder="" aria-label="Usuario" />
                                            </div>
                                            <div class="mb-3">
                                                <label class="text-gray-600 small text-left" for="passwordExample">Contrase&ntilde;a</label>
                                                <input class="form-control form-control-solid" v-on:keyup.enter="redirectHome" v-model="pass" type="password" placeholder="" aria-label="Contraseña"/>
                                            </div>
                                            <div class="mb-3 text-right" style="text-align:right"><a class="small btn-link" @click="redirectRecuperarPass">¿Olvidaste la contrase&ntilde;a?</a></div>
                                            <div class="d-flex align-items-center justify-content-center mb-0">
                                                <a class="btn btn-primary color-btn" @click="redirectHome">Ingresar</a>
                                            </div>
                                        </form>
                                    </div>
                                    <hr class="my-0" />
                                    <div class="card-body px-5 py-4">
                                        <div class="small text-center">
                                            ¿Nuevo usuario?
                                            <a  @click="redirectRegister" class="btn-link">¡Crear cuenta!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
   
        </div>
  </div>
</template>


<style>
/*Color del fondo, sobreescribiendo variable de del tema de boostrap SB ADMIN*/
:root {
    --bs-body-bg: #F5F5F5;
}

.titulo-login{
    font-size: 30px;
    color: rgb(0, 47, 135);
}
.color-btn{
    background: #002f87 !important;
    border:none;
}
.btn-link{
    color:#0061f2;
    cursor: pointer;
}
</style>

<script>
import navBar from "../components/landing/navBar";
import router from '@/router/index.js' // Vue router instance
import spinner from '../components/spinner'
import Vue from 'vue'
import Spinner from "../components/spinner"

export default {
  name: "Home",
  components: {
    navBar,Spinner
  },
  mounted: function () {
    this.$store.commit("SET_BLOQUEADO", false);
    this.$store.commit("login/SET_EMPRESAS_BY_USER", null);

  },
  methods: {
    redirectRegister() {
        router.push("/registrarse");
    },
    redirectRecuperarPass(){
        router.push("/recuperarPass");
    },
    redirectHome(){
        if( (this.user == null || this.user.length<1) || (this.pass == null || this.pass.length<1))
            return Vue.swal("Portal Proveedoras/es","El usuario y/o contraseña son obligatorios.","info");
         else{
            this.$store.dispatch("login/getLogin");
         }
    }
  },
  computed: {
    user: {
        get() {
          return this.$store.state.login.user;
        },
        set(value) {
          this.$store.commit("login/SET_USER", value.toLowerCase().trim());
        },
    },
    pass: {
        get() {
          return this.$store.state.login.pass;
        },
        set(value) {
          this.$store.commit("login/SET_PASS", value);
        },
    },
  },
};
</script>
